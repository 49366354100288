var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wheel-of-fortune"},[_c('div',{staticClass:"wheel-of-fortune__header flex flex-row justify-space-between"},[_c('AddLimitation',{ref:"addWheelFlyout",on:{"save":function($event){return _vm.onFlyoutSave()}}}),_c('v-btn',{staticClass:"ma-2 white--text p-4",attrs:{"color":"green"},on:{"click":function($event){return _vm.onOpenFlyout(undefined, 'new')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("common.newCampaign"))+" ")],1)],1),_c('v-card',{staticClass:"wheel-of-fortune--card"},[_c('div',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.$t("common.active"))+" ")]),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.activeCampaigns,"options":_vm.pagination,"server-items-length":_vm.total,"footer-props":{
        'items-per-page-options': [10, 20, 50, 100],
        showFirstLastPage: true
      },"loading":_vm.activeCampaignsLoading},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.link",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center position-relative"},[_c('v-icon',{staticClass:"mr-2 flex-grow-0 flex-shrink-0 p-2",attrs:{"small":"","color":"indigo"},on:{"click":function($event){return _vm.copyLink(item)}}},[_vm._v(" mdi-content-copy ")]),_c('span',{staticClass:"link indigo--text",on:{"click":function($event){return _vm.openLinkInNewWindow(item)}}},[_vm._v(" "+_vm._s(_vm.getItemLink(item))+" ")]),(_vm.isCopied)?_c('div',{staticClass:"popup"},[_vm._v(" Copied! ")]):_vm._e()],1)]}},{key:"item.country",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.countryCode)+" ")]}},{key:"item.validFrom",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatUTCToLocal(item.startsAt))+" ")]}},{key:"item.validUntil",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatUTCToLocal(item.endsAt))+" ")]}},{key:"item.limitForCampaign",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.limit)+" € ")]}},{key:"item.voucherVariants",fn:function(ref){
      var item = ref.item;
return _vm._l((item.options),function(option,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(option.value)+"€"+_vm._s(index >= item.options.length - 1 ? "" : ", ")+" ")])})}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.onOpenFlyout(item, 'edit')}}},[_c('v-icon',{staticClass:"pr-3"},[_vm._v("mdi-pencil-outline")]),_vm._v(" "+_vm._s(_vm.$t("common.edit"))+" ")],1),_c('v-list-item',{on:{"click":function($event){return _vm.onOpenFlyout(item, 'copy')}}},[_c('v-icon',{staticClass:"pr-3"},[_vm._v("mdi-content-copy")]),_vm._v(" "+_vm._s(_vm.$t("common.copy"))+" ")],1),_c('v-list-item',{on:{"click":function($event){return _vm.delRecord(item)}}},[_c('v-icon',{staticClass:"pr-3"},[_vm._v("mdi-delete-forever-outline")]),_vm._v(" "+_vm._s(_vm.$t("common.delete"))+" ")],1)],1)],1)]}}])}),_c('div',{staticClass:"card-title pt-10"},[_vm._v(" "+_vm._s(_vm.$t("common.archive"))+" ")]),_c('v-data-table',{staticClass:"elevation-2 archive-table",attrs:{"headers":_vm.archiveHeaders,"items":_vm.archiveCampaigns,"options":_vm.paginationArchive,"server-items-length":_vm.totalArchive,"footer-props":{
        'items-per-page-options': [10, 20, 50, 100],
        showFirstLastPage: true
      },"loading":_vm.archiveCampaignsLoading},on:{"update:options":function($event){_vm.paginationArchive=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.country",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.countryCode)+" ")]}},{key:"item.validFrom",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatUTCToLocal(item.startsAt))+" ")]}},{key:"item.validUntil",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatUTCToLocal(item.endsAt))+" ")]}},{key:"item.limitForCampaign",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.limit)+" € ")]}},{key:"item.voucherVariants",fn:function(ref){
      var item = ref.item;
return _vm._l((item.options),function(option,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(option.value)+"€"+_vm._s(index >= item.options.length - 1 ? "" : ", ")+" ")])})}}])})],1),_c('ConfirmDialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }