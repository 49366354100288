export const ACTIVE_HEADERS = ({ i18n }) => [
  {
    text: i18n.t("common.nameOfLimit"),
    value: "name",
    sortable: false,
    align: "left"
  },
  {
    text: i18n.t("common.link"),
    value: "link",
    sortable: false,
    align: "left"
  },
  {
    text: i18n.t("commonTable.country"),
    value: "country",
    sortable: false,
    width: "10%",
    align: "center"
  },
  {
    text: i18n.t("common.validFrom"),
    value: "validFrom",
    sortable: false,
    width: "10%",
    align: "center"
  },
  {
    text: i18n.t("common.validUntil"),
    value: "validUntil",
    sortable: false,
    width: "10%",
    align: "center"
  },
  {
    text: i18n.t("common.limitForCampaign"),
    value: "limitForCampaign",
    sortable: false,
    width: "10%",
    align: "center"
  },
  {
    text: i18n.t("commonTable.voucherVariants"),
    value: "voucherVariants",
    sortable: false,
    width: "10%",
    align: "center"
  },
  {
    text: " ",
    value: "actions",
    width: "3%",
    sortable: false
  }
];
export const ARCHIVE_HEADERS = ({ i18n }) => [
  {
    text: i18n.t("common.nameOfLimit"),
    value: "name",
    sortable: false,
    align: "left"
  },
  {
    text: i18n.t("commonTable.country"),
    value: "country",
    sortable: false,
    width: "10%",
    align: "center"
  },
  {
    text: i18n.t("common.validFrom"),
    value: "validFrom",
    sortable: false,
    width: "10%",
    align: "center"
  },
  {
    text: i18n.t("common.validUntil"),
    value: "validUntil",
    sortable: false,
    width: "10%",
    align: "center"
  },
  {
    text: i18n.t("common.limitForCampaign"),
    value: "limitForCampaign",
    sortable: false,
    width: "10%",
    align: "center"
  },
  {
    text: i18n.t("commonTable.voucherVariants"),
    value: "voucherVariants",
    sortable: false,
    width: "10%",
    align: "center"
  }
];
