import UrlHandlerApiService from "@/core/services/url-handler.service";
import Vue from "vue";
/**
 * Service to call HTTP request via Axios
 */
const WheelsApiService = {
  URL: "",
  async init() {
    this.URL = await UrlHandlerApiService.getBaseUrl();
  },

  WHEEL_OF_FORTUNE_CAMPAIGNS: `api/promo/v1/wheel-of-fortune/campaigns`,
  WHEEL_OF_FORTUNE_CAMPAIGNS_ID: id => `api/promo/v1/wheel-of-fortune/campaigns/${id}`,

  getWheelCampaigns(config) {
    return Vue.axios.get(this.URL + this.WHEEL_OF_FORTUNE_CAMPAIGNS, config).catch(error => {
      throw new Error(`[KT] PromoWheelApiService ${error}`);
    });
  },
  getWheelCampaign(id) {
    return Vue.axios.get(this.URL + this.WHEEL_OF_FORTUNE_CAMPAIGNS_ID(id));
  },
  createWheelCampaign(body) {
    return Vue.axios.post(this.URL + this.WHEEL_OF_FORTUNE_CAMPAIGNS, body);
  },
  updateWheelCampaign(body) {
    return Vue.axios.patch(this.URL + this.WHEEL_OF_FORTUNE_CAMPAIGNS_ID(body.id), body);
  },
  deleteWheelCampaign(id) {
    return Vue.axios.delete(this.URL + this.WHEEL_OF_FORTUNE_CAMPAIGNS_ID(id));
  }
};

export default WheelsApiService;
