import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import KTLayoutFlyout from "@/assets/js/layout/extended/quick-flyout.js";
import { VALIDATORS } from "@/common/validators";
import ApiService from "@/core/services/api.service";
import SchedulerApiService from "@/core/services/api.service.scheduler";
import { formatUTCToLocal, formatLocalToUTC } from "@/common/date-format";
import WheelsApiService from "@/core/services/api.service.wheel";
import CountryService from "@/core/services/country.service";
import moment from "moment";

export default {
  props: {
    //program: Object,
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "add-limitation",
  data() {
    return {
      valid: false,
      rules: {
        required: VALIDATORS.required
      },
      countries: [],
      countriesLoading: false,
      type: "new",
      activeCountry: null,
      selected: {
        country: null
      },
      form: {}
    };
  },
  created() {
    this.setForm();
  },
  async mounted() {
    await SchedulerApiService.init();
    await ApiService.init();
    await WheelsApiService.init();
    this.activeCountry = CountryService.getActiveCountry();
  },
  computed: {
    isFormValid() {
      const formValid = this.$refs.form?.validate();
      // const shopsExist = this.form.shops.length;
      const datesExist = this.form.startsAt && this.form.endsAt;

      return formValid && datesExist;
    }
  },
  methods: {
    initFlyout() {
      KTLayoutFlyout.init(this.$refs["add_limitation"]);
      const el = KTLayoutFlyout.getElement();
      this.flyoutRef = new KTOffcanvas(el);
    },
    setForm(data) {
      this.form = {
        id: data?.id,
        name: data?.name || null,
        startsAt: this.type !== "copy" ? formatUTCToLocal(data?.startsAt) : null,
        endsAt: this.type !== "copy" ? formatUTCToLocal(data?.endsAt) : null,
        limit: data?.limit || null,
        countryCode: data?.countryCode || null,
        voucherOptions: data?.options || []
      };

      if (data?.countryCode) {
        this.selected.country =
          this.countries.find(country => country.code === data?.countryCode) || null;
      } else {
        this.selected.country =
          this.countries.find(country => country.id === this.activeCountry) || null;
      }
    },
    async open(obj) {
      this.type = obj?.type || "new";

      this.initFlyout();
      this.flyoutRef.show();

      this.resetSelectedData();

      await this.getCountries();
      this.setForm(obj.data);
    },
    resetSelectedData() {
      this.selected = {
        country: null,
        voucher: null
      };
    },
    close() {
      this.setForm();
      if (this.$refs.startsAt) {
        this.$refs.startsAt.clearHandler();
      }

      if (this.$refs.endsAt) {
        this.$refs.endsAt.clearHandler();
      }

      this.flyoutRef.hide();
    },
    previousState() {
      this.$router.go(-1);
    },
    async validateAndSave() {
      const isValid = this.$refs.form.validate();
      const datesExist = this.form.startsAt && this.form.endsAt;

      if (datesExist) {
        const areValidDates = moment(this.form.startsAt).isBefore(this.form.endsAt);

        if (!areValidDates) {
          const translate = this.$i18n.t("common.validUntil");
          this.$bvToast.toast("Error: " + translate, {
            solid: true,
            variant: "warning",
            autoHideDelay: 1000
          });

          // VALIDATION FAILED, RETURN
          return false;
        }
      }

      if (isValid) {
        this.save();
      }
    },

    getCountries() {
      this.countriesLoading = true;
      return SchedulerApiService.getCountries()
        .then(res => {
          this.countries = res.data || [];
        })
        .finally(() => {
          this.countriesLoading = false;
        });
    },

    addNewVariant(voucher) {
      if (!this.form.voucherOptions) {
        this.form.voucherOptions = [];
      }
      const newVariant = { value: voucher };
      this.form.voucherOptions.push(newVariant);
      this.selected.voucher = "";
    },
    removeVoucher(voucher) {
      const index = this.form.voucherOptions.findIndex(option => option.value === voucher.value);
      if (index !== -1) {
        this.form.voucherOptions.splice(index, 1);
        this.selected.voucher = 0;
      }
    },

    async save() {
      this.form.countryCode = this.selected.country.code;
      if (this.type === "copy") {
        console.log(this.form);
        delete this.form.id;
      }
      const postModel = { ...this.form };

      if (postModel?.startsAt) {
        postModel.startsAt = formatLocalToUTC(postModel.startsAt);
      }

      if (postModel?.endsAt) {
        postModel.endsAt = formatLocalToUTC(postModel.endsAt);
      }

      let promise = null;
      if (this.type === "edit") {
        promise = WheelsApiService.updateWheelCampaign(postModel);
      } else {
        promise = WheelsApiService.createWheelCampaign(postModel);
      }

      promise
        .then(response => {
          this.close();
          this.setForm();
          this.$emit("save", {
            formData: postModel,
            response
          });

          this.$bvToast.toast(this.$i18n.t("common.savedSuccessfully"), {
            title: this.$i18n.t("menu.wheel"),
            solid: true,
            variant: "success",
            autoHideDelay: 1000
          });
        })
        .catch(error => {
          console.log(error);
          if (error.response && error.response.data && error.response.data.errorCode === 4011) {
            // Handle error with errorCode 4011
            this.$bvToast.toast(
              `Error! You cannot create a campaign for the same country where a campaign already exists in that period.`,
              {
                title: this.$i18n.t("menu.wheel"),
                solid: true,
                variant: "warning",
                noCloseButton: false
              }
            );
          } else {
            // Handle other errors
            this.$bvToast.toast(`Error! Service currently unavailable`, {
              title: this.$i18n.t("menu.wheel"),
              solid: true,
              variant: "error",
              autoHideDelay: 2000
            });
          }
        });
    }
  }
};
